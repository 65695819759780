import * as React from 'react'
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export default function QuestionFour({handleQ, q4Value}) {
  return (
    <div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={q4Value}
          onChange={(e) => handleQ(e, 4)}
          >
          <FormControlLabel value="asap" control={<Radio />} label="As soon as possible" />
          <FormControlLabel value="week" control={<Radio />} label="Within the next week" />
          <FormControlLabel value="month" control={<Radio />} label="Within the next month" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}