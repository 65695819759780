import * as React from 'react'
import TextField from '@mui/material/TextField';

export default function QuestionFive({handleQ, q5Value}) {
  return (
    <div>
      <div style={{ padding: "8px 0px 20px 0px"}}>
        Your contact information is secure. We'll only use it to respond to your request,<span style={{ fontWeight: 600}}> no spam.</span>
      </div>
      <TextField
        label="Enter Contact number or Email"
        variant="outlined"
        fullWidth
        value={q5Value}
        onChange={(e) => handleQ(e, 5)}
      />
    </div>
  )
}